<template>
	<div>
		<global-menu />

		<f-default-header
			:title="$t('pages.settings.verticalActivityRel.subtitle.allActivities')"
			:subtitle="
				$t('pages.settings.verticalActivityRel.subtitle.configRelation')
			" />

		<missings-list />

		<f-actions-btn-holder>
			<v-btn color="primary" elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import MissingsList from "./../../../components/settings/verticalActivityRel/TermsList.vue";
import { SETTINGS_ROUTES } from "../../../router/settings";
import GlobalMenu from "../../../components/settings/verticalActivityRel/GlobalMenu.vue";

export default {
	components: {
		MissingsList,
		GlobalMenu,
	},
	methods: {
		async back() {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_INDEX,
			});
		},
	},
};
</script>
